import React from 'react';
import { Stage, Layer, Image, Transformer, Text } from 'react-konva';
import { InfinitySpin, ThreeCircles } from 'react-loader-spinner';
import { Button, Grid, Drawer, IconButton } from '@mui/material';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie'; // Import js-cookie
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import Navbar from './Navbar';
import CustomTextEditor from './CustomTextEditor';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import Music from './Music';
import VerticalTabs from './VerticalTabs'; // Import the new VerticalTabs component
import Media from './Media';
import Voice_clips from './Voice_clips';
import Gif from './Gif';
import Static_image from './Static_image';
import Canvas_text from './Canvas_text';
import Asset_component from './Asset_component';
import Steps from './Steps';
import HorizontalTabs from './HorizontalTabs';
import Hidden from '@mui/material/Hidden';

function ContinueButton() {
    const navigate = useNavigate();

    return (
        <Button variant="contained" onClick={() => navigate('/Memorial')}>
            Continue
        </Button>
    );
}

function ExitButton() {
    const navigate = useNavigate();

    return (
        <Button variant="contained" onClick={() => navigate('/Memorial_list')}>
            Exit
        </Button>
    );
}

// Initialize Supabase
const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const BackgroundImage = ({ src, stageWidth, stageHeight }) => {
    const imageRef = React.useRef();
    const [image] = React.useState(new window.Image());

    React.useEffect(() => {
        image.src = src;
        image.onload = () => {
            if (imageRef.current) {
                imageRef.current.getLayer().draw();
            }
        };
    }, [src, image]);

    return (
        <Image
            image={image}
            ref={imageRef}
            width={stageWidth}
            height={stageHeight}
            listening={false} // Ignore pointer events
            fit="cover" // Scale the image to cover the entire area of the Image node
        />
    );
};



class Edit_memorial extends React.Component {
    stageRef = React.createRef();

    state = {
        assets: [],
        history: [],
        historyIndex: -1,
        selectedAsset: null,
        selectedCategory: null,
        background: '/images/landscape1.png',
        loading: true, // Add a new state variable for loading
        assetsLoaded: 0, // Add a new state variable for the number of assets loaded
        contextMenuVisible: false,
        contextMenuPosition: { top: 0, left: 0 },
        contextMenuActions: {
            duplicate: null,
            delete: null,
            textDrawerOpen: false, // State for text drawer
            // Add state for tracking asset dimensions and position
            assetX: 0,
            assetY: 0,
            assetWidth: 100, // default width
            assetHeight: 100, // default height
            stageScale: 1,
            stageWidth: window.innerWidth,
            stageHeight: window.innerHeight
        }
    };


    async componentDidMount() {
        const userId = Cookies.get('userId');
        const memorialId = Cookies.get('memorialId');

        const { data, error } = await supabase
            .from('memorials')
            .select('memorial_canvas')
            .eq('owner_id', userId)
            .eq('memorial_Id', memorialId)
            .single();

        if (error) {
            console.error('Error fetching memorial data:', error.message);
        } else if (data) {
            const memorialCanvas = data.memorial_canvas;

            // Assign unique IDs to each asset and rename the name field to src
            const updateAssetProperties = (asset, index) => {
                return {
                    ...asset,
                    id: Math.random(), // You can use any unique identifier here
                    src: asset.name, // Rename the name field to src
                    x: asset.x,
                    y: asset.y,
                    width: asset.width,
                    height: asset.height,
                    scaleX: asset.scaleX,
                    scaleY: asset.scaleY
                };
            };

            // Assign unique IDs to each asset and rename the name field to src
            const assetsWithIds = memorialCanvas.assets.map(updateAssetProperties);

            this.setState({
                assets: assetsWithIds,
                background: memorialCanvas.backgroundImage,
            });
        }
        window.addEventListener('resize', this.handleResize);
        this.handleResize(); // initial scale
    }


    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
        const container = document.querySelector('.canvas-container');
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;

        const stageWidth = 1366; // base width for design
        const stageHeight = 768 // base height for design

        // Calculate scale factors
        const scaleX = containerWidth / stageWidth;
        const scaleY = containerHeight / stageHeight;
        const scale = Math.min(scaleX, scaleY);

        // Calculate scaled dimensions
        const scaledWidth = stageWidth * scale;
        const scaledHeight = stageHeight * scale;

        // Calculate maximum allowed translation to keep Stage within bounds
        const maxTranslateX = Math.min(0, containerWidth - scaledWidth);
        const maxTranslateY = Math.min(0, containerHeight - scaledHeight);

        this.setState({
            stageScale: scale,
            stageWidth: scaledWidth,
            stageHeight: scaledHeight,
            maxTranslateX: maxTranslateX,
            maxTranslateY: maxTranslateY,
        }, () => {
            container.style.width = `${scaledWidth}px`;
            container.style.height = `${scaledHeight}px`;
        });
    };

    handleAssetLoad = () => {
        this.setState(prevState => ({ assetsLoaded: prevState.assetsLoaded + 1 }), () => {
            if (this.state.assetsLoaded === memorial.assets.length + 1) { // +1 for the background image
                this.setState({ loading: false });
            }
        });
    };

    toggleDrawer = (open) => {
        this.setState({ drawerOpen: open });
    };

    selectAsset = (src, category) => () => {
        if (category === 'Landscapes') {
            this.setState({ background: src });
        } else {
            const newAsset = { id: Math.random(), src: src, category: category, selected: false, x: 100, y: 100, width: 200, height: 200 };
            this.setState(prevState => {
                const newAssets = [...prevState.assets, newAsset];
                const newHistory = [...prevState.history];
                if (prevState.historyIndex < prevState.history.length - 1) {
                    newHistory.splice(prevState.historyIndex + 1);
                }
                newHistory.push(newAssets);
                return {
                    assets: newAssets,
                    history: newHistory,
                    historyIndex: newHistory.length - 1,
                };
            });
        }
    };


    toggleTextDrawer = (open) => {
        this.setState({ textDrawerOpen: open });
    };

    handleTextEditorChange = (content) => {
        this.setState({ textEditorContent: content });
    };



    insertText = (formattedText) => {
        const newAsset = {
            id: Math.random(),
            type: 'text',
            text: formattedText.text,
            fontSize: formattedText.fontSize,
            fontFamily: formattedText.fontFamily,
            isBold: formattedText.isBold,
            isItalic: formattedText.isItalic,
            isUnderline: formattedText.isUnderline,
            fill: formattedText.fill,
            x: 100,
            y: 100,
            scaleX: formattedText.scaleX,
            scaley: formattedText.scaleY,
            selected: false,
        };

        this.setState(prevState => ({
            assets: [...prevState.assets, newAsset]
        }));
        this.toggleTextDrawer(false);
    };



    toggleSelectAsset = (id) => () => {
        const asset = this.state.assets.find(a => a.id === id);
        this.setState({
            assets: this.state.assets.map(a => (a.id === id ? { ...a, selected: !a.selected } : { ...a, selected: false })),
            assetX: asset.x,
            assetY: asset.y,
            assetWidth: asset.width,
            assetHeight: asset.height,
            assetScaleX: asset.scaleX,
            assetScaleY: asset.scaleY
        });
    };

    deleteAsset = (id) => () => {
        this.setState(prevState => {
            const newAssets = prevState.assets.filter((asset) => asset.id !== id);
            const newHistory = [...prevState.history];
            if (prevState.historyIndex < prevState.history.length - 1) {
                newHistory.splice(prevState.historyIndex + 1);
            }
            newHistory.push(newAssets);
            return {
                assets: newAssets,
                history: newHistory,
                historyIndex: newHistory.length - 1,
            };
        });
    };

    duplicateAsset = (id) => () => {
        const stage = this.stageRef.current.getStage();
        const nodeToDuplicate = stage.findOne(node => node.id() === id);

        if (nodeToDuplicate) {
            let type;
            let category;
            if (nodeToDuplicate.attrs.src) {
                // If the node has a src attribute, determine the type based on the file extension
                const extension = nodeToDuplicate.attrs.src.split('.').pop();
                if (extension === 'gif') {
                    category = 'Animations';
                } else if (extension === 'png') {
                    type = 'image';
                }
            } else {
                // If the node doesn't have a src attribute, it must be a text node
                type = 'text';
            }

            const duplicatedNode = {
                ...nodeToDuplicate.attrs,
                id: Math.random(),
                type: type, // Set the type field
                category: category,
                x: nodeToDuplicate.attrs.x + 50,
                y: nodeToDuplicate.attrs.y + 50,
                scaleX: nodeToDuplicate.attrs.scaleX || 1,
                scaleY: nodeToDuplicate.attrs.scaleY || 1
            };

            this.setState(prevState => ({
                assets: [...prevState.assets, duplicatedNode]
            }));
        }
    };


    bringForward = (id) => () => {
        console.log('bringForward called with id:', id);
        this.setState(prevState => {
            const index = prevState.assets.findIndex(asset => asset.id === id);
            console.log('Asset index:', index);
            if (index < prevState.assets.length - 1) {
                const assets = [...prevState.assets];
                const asset = assets[index];
                assets.splice(index, 1); // Remove the asset from its current position
                assets.splice(index + 1, 0, asset); // Insert the asset at its new position
                console.log('Assets after bringForward:', assets);
                return { assets };
            }
        });
    };

    sendBackward = (id) => () => {
        console.log('sendBackward called with id:', id);
        this.setState(prevState => {
            const index = prevState.assets.findIndex(asset => asset.id === id);
            console.log('Asset index:', index);
            if (index > 0) {
                const assets = [...prevState.assets];
                const asset = assets[index];
                assets.splice(index, 1); // Remove the asset from its current position
                assets.splice(index - 1, 0, asset); // Insert the asset at its new position
                console.log('Assets after sendBackward:', assets);
                return { assets };
            }
        });
    };

    undo = () => {
        this.setState(prevState => {
            if (prevState.historyIndex > 0) {
                return {
                    assets: prevState.history[prevState.historyIndex - 1],
                    historyIndex: prevState.historyIndex - 1,
                };
            } else {
                return prevState;
            }
        });
    };



    redo = () => {
        this.setState(prevState => {
            if (prevState.historyIndex < prevState.history.length - 1) {
                return {
                    assets: prevState.history[prevState.historyIndex + 1],
                    historyIndex: prevState.historyIndex + 1,
                };
            } else {
                return prevState;
            }
        });
    };

    handleStageClick = (e) => {
        // Deselect the asset if clicked on empty area
        if (e.target === e.target.getStage()) {
            this.setState({
                assets: this.state.assets.map((asset) => ({ ...asset, selected: false })),
            });
        }
        console.log("Assets:", this.state.assets); // Add this line to log the assets array
    };

    saveToDatabase = async () => {
        const userId = Cookies.get('userId'); // Read the user ID from the cookie
        console.log('userId:', userId);
        const memorial_Id = Cookies.get('memorialId'); // Read the memorial ID from the cookie
        console.log('memorial_Id:', memorial_Id);

        const stage = this.stageRef.current.getStage();
        const nodes = stage.find(node => node.className === 'Image' || node.className === 'Text');
        const assets = nodes.map(node => {
            if (node.className === 'Image') {
                // Handle image node
                const isGif = node.attrs.image instanceof HTMLCanvasElement;
                let src = isGif ? node.attrs.src : node.attrs.image.src;

                // Extract relative path from the absolute URL
                const relativeSrc = src.split('/').pop();
                const path = "/images/" + relativeSrc;

                // Add a condition to check if the image is not the background image
                if (path !== this.state.background) {
                    return {
                        type: 'image',
                        name: path,
                        x: node.x(),
                        y: node.y(),
                        width: node.scaleX() * node.width(),
                        height: node.scaleY() * node.height(),
                    };
                }
            } else if (node.className === 'Text') {
                // Handle text node
                return {
                    type: 'text',
                    text: node.text(),
                    fontSize: node.fontSize(),
                    fontFamily: node.fontFamily(),
                    fontStyle: node.fontStyle(), // bold, italic
                    textDecoration: node.textDecoration(), // underline
                    fill: node.fill(), // color
                    x: node.x(),
                    y: node.y(),
                    width: node.width(),
                    height: node.height(),
                    scaleX: node.scaleX(),
                    scaleY: node.scaleY(),
                };
            }
        }).filter(Boolean); // Filter out undefined values

        const memorialCanvas = {
            assets: assets,
            backgroundImage: this.state.background,
        };

        console.log('memorialCanvas:', memorialCanvas);

        //Cookies.set('memorial_Id', memorial_Id); // Save the memorial ID as a cookie

        const { error } = await supabase
            .from('memorials')
            .update({ memorial_canvas: memorialCanvas })
            .eq('owner_id', userId)
            .eq('memorial_Id', memorial_Id);

        if (error) {
            console.log('Error updating data: ', error);
        } else {
            console.log('Data updated successfully');
            alert("Design Saved Successfully");
        }
    };




    render() {
        const { loading, stageScale, stageWidth, stageHeight } = this.state;

        const handleTabChange = (event, newValue) => {
            this.setState((prevState) => ({
                ...prevState,
                openElementsDrawer: newValue === 0,
                openTextDrawer: newValue === 1,
                openMusicDrawer: newValue === 2,
                openVoiceDrawer: newValue === 3,
                openMediaDrawer: newValue === 4,
            }));
        };

        const handleDrawerClose = () => {
            this.setState((prevState) => ({
                ...prevState,
                openElementsDrawer: false,
                openTextDrawer: false,
                openMusicDrawer: false,
                openVoiceDrawer: false,
                openMediaDrawer: false,
            }));
        };

        const handleTabChange2 = (event, newValue) => {
            this.setState((prevState) => ({
                ...prevState,
                openElementsDrawer2: newValue === 0,
                openTextDrawer2: newValue === 1,
                openMusicDrawer2: newValue === 2,
                openVoiceDrawer2: newValue === 3,
                openMediaDrawer2: newValue === 4,
            }));
        };

        const handleDrawerClose2 = () => {
            this.setState((prevState) => ({
                ...prevState,
                openElementsDrawer2: false,
                openTextDrawer2: false,
                openMusicDrawer2: false,
                openVoiceDrawer2: false,
                openMediaDrawer2: false,
            }));
        };

        const styles = `
        .canvas-container {
        position: relative;
        width: 100%;
        padding-top: 56.25%; /* 16:9 aspect ratio (9/16 * 100%) */
      }
      
      .canvas-container > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      
      `;

        const backgroundAspectRatio = 16 / 9; // Desired aspect ratio
        const { contextMenuVisible, contextMenuPosition, contextMenuActions } = this.state;
        const { memorial } = this.state; // Access memorial from the state
        return (
            <div>
                <Grid container>
                    <Grid
                        item
                        xs={1}
                        sx={{
                            height: '100vh',
                            position: 'sticky',
                            top: 0,
                            display: { xs: 'none', sm: 'block' },
                        }}
                    >
                        <VerticalTabs
                            value={
                                this.state.openElementsDrawer
                                    ? 0
                                    : this.state.openTextDrawer
                                        ? 1
                                        : this.state.openMusicDrawer
                                            ? 2
                                            : false
                            }
                            handleChange={handleTabChange}
                        />
                    </Grid>
                    <Grid item xs={11}>
                        <Grid container direction="column" alignItems="center">
                            <br />
                            <div style={{ width: '80%' }}> {/* Adjust the width here */}

                            </div>
                            <Grid item xs={12}>
                                <h3>Update Your Memorial Design</h3>
                            </Grid>
                            <Grid item xs={12}>

                            </Grid>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                                <div className="canvas-container" ref={this.containerRef} style={{ width: { stageWidth }, height: { stageHeight }, border: '1px solid black', borderRadius: '10px', overflow: 'hidden', position: 'relative', zIndex: 0 }}>
                                    {loading && (
                                        <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.44)' }}>
                                            <h3 style={{ color: 'white', fontFamily: 'Verdana' }}>Loading Memorial...</h3><br /><br /><br />
                                            <InfinitySpin color="#3aaff2" height={200} width={200} />
                                        </div>
                                    )}
                                    <Stage
                                        width={stageWidth}
                                        height={stageHeight}
                                        scaleX={stageScale}
                                        scaleY={stageScale}
                                        draggable
                                        dragBoundFunc={(pos) => {
                                            const { maxTranslateX, maxTranslateY } = this.state;
                                            let newX = pos.x;
                                            let newY = pos.y;

                                            // Apply constraints
                                            if (pos.x > 0) {
                                                newX = 0;
                                            } else if (pos.x < maxTranslateX) {
                                                newX = maxTranslateX;
                                            }

                                            if (pos.y > 0) {
                                                newY = 0;
                                            } else if (pos.y < maxTranslateY) {
                                                newY = maxTranslateY;
                                            }

                                            return { x: newX, y: newY };
                                        }}
                                        ref={this.stageRef}
                                        onClick={this.handleStageClick}
                                    >
                                        <Layer>
                                            <BackgroundImage
                                                src={this.state.background}

                                            />
                                            {this.state.assets.map((asset) => (
                                                asset.type === 'text' ? (
                                                    <Canvas_text
                                                        key={asset.id}
                                                        id={asset.id}
                                                        x={asset.x}
                                                        y={asset.y}
                                                        text={asset.text}
                                                        fontFamily={asset.fontFamily}
                                                        fontSize={asset.fontSize}
                                                        fontStyle={asset.fontStyle}
                                                        fontWeight={asset.fontWeight}
                                                        scaleX={asset.scaleX}
                                                        scaleY={asset.scaleY}
                                                        fill={asset.fill}
                                                        isSelected={asset.selected}
                                                        onSelect={this.toggleSelectAsset(asset.id)}
                                                        onDelete={this.deleteAsset(asset.id)}
                                                        onDuplicate={this.duplicateAsset(asset.id)}
                                                        onBringForward={this.bringForward(asset.id)}
                                                        onSendBackward={this.sendBackward(asset.id)}
                                                    />
                                                ) : (
                                                    asset.name?.endsWith("gif") ? (
                                                        <Gif
                                                            key={asset.id}
                                                            id={asset.id}
                                                            src={asset.src}
                                                            x={asset.x}
                                                            y={asset.y}
                                                            width={asset.width}
                                                            height={asset.height}
                                                            scaleX={asset.scaleX}
                                                            scaleY={asset.scaleY}
                                                            isSelected={asset.selected}
                                                            onSelect={this.toggleSelectAsset(asset.id)}
                                                            onDelete={this.deleteAsset(asset.id)}
                                                            onDuplicate={this.duplicateAsset(asset.id)}
                                                            onBringForward={this.bringForward(asset.id)}
                                                            onSendBackward={this.sendBackward(asset.id)}
                                                        />
                                                    ) : asset.category === "Animations" ? (
                                                        <Gif
                                                            key={asset.id}
                                                            id={asset.id}
                                                            src={asset.src}
                                                            x={asset.x}
                                                            y={asset.y}
                                                            width={asset.width}
                                                            height={asset.height}
                                                            scaleX={asset.scaleX}
                                                            scaleY={asset.scaleY}
                                                            isSelected={asset.selected}
                                                            onSelect={this.toggleSelectAsset(asset.id)}
                                                            onDelete={this.deleteAsset(asset.id)}
                                                            onDuplicate={this.duplicateAsset(asset.id)}
                                                            onBringForward={this.bringForward(asset.id)}
                                                            onSendBackward={this.sendBackward(asset.id)}
                                                        />
                                                    ) : (
                                                        <Static_image
                                                            key={asset.id}
                                                            id={asset.id}
                                                            src={asset.src}
                                                            x={asset.x}
                                                            y={asset.y}
                                                            width={asset.width}
                                                            height={asset.height}
                                                            scaleX={asset.scaleX}
                                                            scaleY={asset.scaleY}
                                                            isSelected={asset.selected}
                                                            onSelect={this.toggleSelectAsset(asset.id)}
                                                            onDelete={this.deleteAsset(asset.id)}
                                                            onDuplicate={this.duplicateAsset(asset.id)}
                                                            onBringForward={this.bringForward(asset.id)}
                                                            onSendBackward={this.sendBackward(asset.id)}
                                                        />
                                                    )
                                                )
                                            ))}
                                        </Layer>
                                    </Stage>
                                </div>
                            </Grid>
                            <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                                <IconButton onClick={this.undo}>
                                    <UndoIcon />
                                </IconButton>
                                <IconButton onClick={this.redo}>
                                    <RedoIcon />
                                </IconButton>
                            </div>
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', gap: '10px', margin: '20px 0' }}>
                                <Button variant="contained" onClick={this.saveToDatabase}>Save Design</Button>

                            </Grid>
                        </Grid>
                        <HorizontalTabs
                            value2={
                                this.state.openElementsDrawer2
                                    ? 0
                                    : this.state.openTextDrawer2
                                        ? 1
                                        : this.state.openMusicDrawer2
                                            ? 2
                                            : this.state.openMusicDrawer2
                                                ? 3
                                                : this.state.openMusicDrawer2
                                                    ? 4
                                                    : false
                            }
                            handleChange2={handleTabChange2}
                        />
                    </Grid>
                </Grid>
                <Drawer sx={{ zIndex: 1301 }} anchor="left" open={this.state.openElementsDrawer} onClose={handleDrawerClose}>
                    {/* ADD ELEMENTS drawer content */}
                    <div style={{ width: '30%', height: '100%', backgroundColor: '#fff', position: 'fixed', top: 0, left: 0, zIndex: 2, overflow: 'auto', scrollbarWidth: 'none' }}>
                        <Asset_component selectAsset={this.selectAsset} />
                    </div>
                </Drawer>
                <Drawer sx={{ zIndex: 1301 }} anchor="left" open={this.state.openTextDrawer} onClose={handleDrawerClose}>
                    {/* ADD TEXT drawer content */}
                    <div style={{ width: '30%', height: '100%', backgroundColor: '#fff', position: 'fixed', top: 0, left: 0, zIndex: 2, overflow: 'auto', scrollbarWidth: 'none' }}>
                        <CustomTextEditor
                            isOpen={this.state.openTextDrawer}
                            onClose={() => this.toggleTextDrawer(false)}
                            onInsert={this.insertText}
                        />
                    </div>
                </Drawer>
                <Drawer sx={{ zIndex: 1301 }} anchor="left" open={this.state.openMusicDrawer} onClose={handleDrawerClose}>
                    {/* ADD MUSIC drawer content */}
                    <div style={{ width: '30%', height: '100%', backgroundColor: '#fff', position: 'fixed', top: 0, left: 0, zIndex: 2, overflow: 'auto', scrollbarWidth: 'none' }}>
                        <Music />
                    </div>
                </Drawer>
                <Drawer sx={{ zIndex: 1301 }} anchor="left" open={this.state.openVoiceDrawer} onClose={handleDrawerClose}>
                    {/* ADD Voice Clips drawer content */}
                    <div style={{ width: '30%', height: '100%', backgroundColor: '#fff', position: 'fixed', top: 0, left: 0, zIndex: 2, overflow: 'auto', scrollbarWidth: 'none' }}>
                        <Voice_clips />
                    </div>
                </Drawer>
                <Drawer sx={{ zIndex: 1301 }} anchor="left" open={this.state.openMediaDrawer} onClose={handleDrawerClose}>
                    {/* ADD Photos / Videos drawer content */}
                    <div style={{ width: '30%', height: '100%', backgroundColor: '#fff', position: 'fixed', top: 0, left: 0, zIndex: 2, overflow: 'auto', scrollbarWidth: 'none' }}>
                        <Media />
                    </div>
                </Drawer>

                <center>
                    <Drawer sx={{ zIndex: 1301 }} anchor="left" open={this.state.openElementsDrawer2} onClose={handleDrawerClose2}>
                        {/* ADD ELEMENTS drawer content */}
                        <div style={{ width: '80%', height: '100%', backgroundColor: '#fff', position: 'fixed', top: 0, left: 0, zIndex: 2, overflow: 'auto', scrollbarWidth: 'none' }}>
                            <Asset_component selectAsset={this.selectAsset} />
                        </div>
                    </Drawer>
                    <Drawer sx={{ zIndex: 1301 }} anchor="left" open={this.state.openTextDrawer2} onClose={handleDrawerClose2}>
                        {/* ADD TEXT drawer content */}
                        <div style={{ width: '80%', height: '100%', backgroundColor: '#fff', position: 'fixed', top: 0, left: 0, zIndex: 2, overflow: 'auto', scrollbarWidth: 'none' }}>
                            <CustomTextEditor
                                isOpen={this.state.openTextDrawer}
                                onClose={() => this.toggleTextDrawer(false)}
                                onInsert={this.insertText}
                            />
                        </div>
                    </Drawer>
                    <Drawer sx={{ zIndex: 1301 }} anchor="left" open={this.state.openMusicDrawer2} onClose={handleDrawerClose2}>
                        {/* ADD MUSIC drawer content */}
                        <div style={{ width: '80%', height: '100%', backgroundColor: '#fff', position: 'fixed', top: 0, left: 0, zIndex: 2, overflow: 'auto', scrollbarWidth: 'none' }}>
                            <Music />
                        </div>
                    </Drawer>
                    <Drawer sx={{ zIndex: 1301 }} anchor="left" open={this.state.openVoiceDrawer2} onClose={handleDrawerClose2}>
                        {/* ADD Voice Clips drawer content */}
                        <div style={{ width: '80%', height: '100%', backgroundColor: '#fff', position: 'fixed', top: 0, left: 0, zIndex: 2, overflow: 'auto', scrollbarWidth: 'none' }}>
                            <Voice_clips />
                        </div>
                    </Drawer>
                    <Drawer sx={{ zIndex: 1301 }} anchor="left" open={this.state.openMediaDrawer2} onClose={handleDrawerClose2}>
                        {/* ADD Photos / Videos drawer content */}
                        <div style={{ width: '80%', height: '100%', backgroundColor: '#fff', position: 'fixed', top: 0, left: 0, zIndex: 2, overflow: 'auto', scrollbarWidth: 'none' }}>
                            <Media />
                        </div>
                    </Drawer>
                </center>

                <div id="context-menu" style={{ display: 'none', position: 'absolute', backgroundColor: 'white', border: '1px solid black' }}>
                    <div id="duplicate">Duplicate</div>
                    <div id="delete">Delete</div>
                    <div id="bringForward">Bring Forward</div>
                    <div id="sendBackward">Send Backward</div>
                </div>
            </div>
        );
    }
}

export default Edit_memorial;
