import React, { useState, useEffect } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { InfinitySpin } from 'react-loader-spinner';
import Modal from '@mui/material/Modal';
import Pagination from '@mui/material/Pagination';
import ShareModal from './ShareModal';

const CustomCard = styled(Card)({
    width: '300px',
    height: '400px',
    margin: '1rem',
    boxShadow: '0px 16px 40px rgba(0, 0, 0, 0.4)',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
});

const CardContentWrapper = styled(CardContent)({
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

const ImageWrapper = styled(Box)({
    flex: '0 1 200px',
    overflow: 'hidden',
});

const Image = styled('img')({
    width: '100%',
    height: '100%',
    objectFit: 'cover',
});

const ContentWrapper = styled(Box)({
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);


const isValidImage = (mediaItem) => {
    const validImageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
    return mediaItem && validImageExtensions.some(extension => mediaItem.url.toLowerCase().endsWith(extension));
};

const getFirstValidImage = (media) => {
    if (!media || media.length === 0) return null;
    return media.find(isValidImage);
};

async function fetchUserName(userId) {
    let { data, error } = await supabase
        .from('Users')
        .select('name, surname')
        .eq('id', userId)
        .single();

    if (error) {
        console.error(error);
        return null;
    }

    return `${data.name} ${data.surname}`;
}

function MemorialModal({ open, handleClose, memorial }) {
    const navigate = useNavigate();

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{
                width: { xs: '90%', sm: '75%' },
                maxHeight: '90%',
                overflow: 'auto',
                bgcolor: 'background.paper',
                p: 2,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}>
                <Typography variant="h6" align="center">{memorial.deceased_name} {memorial.deceased_surname}</Typography>
                <ReactQuill value={memorial.about} readOnly theme="snow" modules={{ toolbar: false }} />
                <Box sx={{ display: 'flex', justifyContent: 'center', gap: 1, mt: 2 }}>
                    <Button variant="contained" onClick={handleClose}>Close</Button>
                    <Button variant="contained" onClick={() => {
                        Cookies.set('memorialId', memorial.memorial_Id);
                        navigate('/Memorial');
                    }}>View Full Memorial</Button>
                </Box>
            </Box>
        </Modal>
    );
}

function Memorial_list() {
    const [memorials, setMemorials] = useState([]);
    const userId = Cookies.get('userId');
    const navigate = useNavigate();
    const [userName, setUserName] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [openModal, setOpenModal] = useState(false);
    const [openShareModal, setOpenShareModal] = useState(false);
    const [currentMemorial, setCurrentMemorial] = useState(null);
    const [currentShareUrl, setCurrentShareUrl] = useState('');  // Add state for the share URL
    const [filters, setFilters] = useState({
        dateOfBirth: '',
        dateOfDeath: '',
        name: '',
        surname: ''
    });
    const [filteredMemorials, setFilteredMemorials] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 9;

    useEffect(() => {
        fetchUserName(userId).then(setUserName);
    }, [userId]);

    useEffect(() => {
        const fetchMemorials = async () => {
            setIsLoading(true);
            try {
                const { data, error } = await supabase
                    .from('memorials')
                    .select('*')
                    .eq('published', true);

                if (error) {
                    throw error;
                }

                if (data) {
                    setMemorials(data.map((item, i) => ({ ...item, id: i + 1 })));
                }
            } catch (error) {
                console.error('Error fetching memorials:', error.message);
            }
            setIsLoading(false);
        };

        if (userId) {
            fetchMemorials();
        }
    }, [userId]);

    const handleOpenModal = (memorial) => {
        setCurrentMemorial(memorial);
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setCurrentMemorial(null);
        setOpenModal(false);
    };

    const applyFilters = () => {
        const filtered = memorials.filter(memorial => {
            if (filters.dateOfBirth && memorial.deceased_date_of_birth !== filters.dateOfBirth) {
                return false;
            }
            if (filters.dateOfDeath && memorial.deceased_date_of_death !== filters.dateOfDeath) {
                return false;
            }
            if (filters.name && !memorial.deceased_name.toLowerCase().includes(filters.name.toLowerCase())) {
                return false;
            }
            if (filters.surname && !memorial.deceased_surname.toLowerCase().includes(filters.surname.toLowerCase())) {
                return false;
            }
            return true;
        });

        setFilteredMemorials(filtered);
    };

    const handleDateOfBirthChange = (event) => {
        setFilters({ ...filters, dateOfBirth: event.target.value });
    };

    const handleDateOfDeathChange = (event) => {
        setFilters({ ...filters, dateOfDeath: event.target.value });
    };

    const handleNameChange = (event) => {
        setFilters({ ...filters, name: event.target.value });
    };

    const handleSurnameChange = (event) => {
        setFilters({ ...filters, surname: event.target.value });
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleShareClick = (memorialId) => {
        const baseUrl = window.location.origin;
        const shareUrl = `${baseUrl}/View_memorial/${memorialId}`;
        setCurrentShareUrl(shareUrl);  // Set the current share URL for the modal
        setOpenShareModal(true);  // Open the share modal
    };


    const paginatedMemorials = (filteredMemorials.length > 0 ? filteredMemorials : memorials).slice((currentPage - 1) * pageSize, currentPage * pageSize);

    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url('/images/background4.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{
                    width: { xs: '90%', sm: '85%' },
                    maxWidth: '1200px',
                    margin: '50px auto',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    borderRadius: '10px'
                }}>
                    <Typography variant="h4" component="h4" gutterBottom style={{ marginBottom: '20px' }}>
                        Hi {userName}, Welcome to Forever.org.za
                    </Typography>
                    <Box sx={{
                        mb: 2,
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'row' },
                        flexWrap: 'wrap',
                        gap: 2,
                        width: '100%',
                        maxWidth: '800px'
                    }}>
                        <Box sx={{ flex: 1 }}>
                            <label style={{ marginBottom: '5px' }}>Date of Birth</label><br />
                            <input
                                type="date"
                                value={filters.dateOfBirth}
                                onChange={handleDateOfBirthChange}
                                style={{
                                    width: '100%',
                                    maxWidth: '200px',
                                    height: '40px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    backgroundColor: 'white'
                                }}
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <label style={{ marginBottom: '5px' }}>Date of Death</label><br />
                            <input
                                type="date"
                                value={filters.dateOfDeath}
                                onChange={handleDateOfDeathChange}
                                style={{
                                    width: '100%',
                                    maxWidth: '200px',
                                    height: '40px',
                                    borderRadius: '4px',
                                    border: '1px solid #ccc',
                                    padding: '8px',
                                    backgroundColor: 'white'
                                }}
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <label style={{ marginBottom: '5px' }}>Name</label><br />
                            <TextField
                                label="Name"
                                variant="outlined"
                                value={filters.name}
                                onChange={handleNameChange}
                                sx={{
                                    width: '100%',
                                    maxWidth: '200px',
                                    height: '40px',
                                    bgcolor: 'white',
                                    '& .MuiInputBase-root': {
                                        height: '100%',
                                        boxSizing: 'border-box'
                                    },
                                    '& .MuiInputLabel-root': {
                                        top: '-6px'
                                    },
                                    '& .MuiInputLabel-shrink': {
                                        top: '0'
                                    }
                                }}
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <label style={{ marginBottom: '5px' }}>Surname</label><br />
                            <TextField
                                label="Surname"
                                variant="outlined"
                                value={filters.surname}
                                onChange={handleSurnameChange}
                                sx={{
                                    width: '100%',
                                    maxWidth: '200px',
                                    height: '40px',
                                    bgcolor: 'white',
                                    '& .MuiInputBase-root': {
                                        height: '100%',
                                        boxSizing: 'border-box'
                                    },
                                    '& .MuiInputLabel-root': {
                                        top: '-6px'
                                    },
                                    '& .MuiInputLabel-shrink': {
                                        top: '0'
                                    }
                                }}
                            />
                        </Box>
                    </Box>
                    <Button variant="contained" onClick={applyFilters} sx={{ alignSelf: 'center', height: '40px' }}>Apply Filters</Button>
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                        {isLoading ? (
                            <InfinitySpin
                                color="#3aaff2"
                                height={200}
                                width={200}
                                timeout={3000}
                            />
                        ) : (
                            paginatedMemorials.map(memorial => (
                                <CustomCard key={memorial.memorial_Id}>
                                    <CardContentWrapper>
                                        <ContentWrapper>
                                            <Typography variant="h6" gutterBottom>
                                                {memorial.deceased_name} {memorial.deceased_surname}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Date of Birth: {memorial.deceased_date_of_birth}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Date of Death: {memorial.deceased_date_of_death}
                                            </Typography>
                                        </ContentWrapper>
                                        <ImageWrapper
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                Cookies.set('memorialId', memorial.memorial_Id);
                                                navigate('/Memorial');
                                            }}
                                        >
                                            {memorial.media && memorial.media.length > 0 && getFirstValidImage(memorial.media) ? (
                                                <Image src={getFirstValidImage(memorial.media)?.url} alt="Media" />
                                            ) : (
                                                <Image src="/images/thumbnail.svg" alt="Default Thumbnail" />
                                            )}
                                        </ImageWrapper>
                                    </CardContentWrapper>
                                    <CardActions disableSpacing>
                                        <IconButton aria-label="add to favorites">
                                            <FavoriteIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleShareClick(memorial.memorial_Id)}>
                                            <ShareIcon />
                                        </IconButton>
                                        {Number(userId) === Number(memorial.owner_id) ? (
                                            <IconButton
                                                aria-label="Edit memorial"
                                                onClick={() => {
                                                    Cookies.set('memorialId', memorial.memorial_Id);
                                                    navigate('/Edit_memorial2');
                                                }}
                                            >
                                                <CreateIcon />
                                            </IconButton>
                                        ) : (
                                            console.error(`User ID mismatch: userId (${userId}) does not match owner_id (${memorial.owner_id})`)
                                        )}

                                        <Box sx={{ ml: 'auto' }}>
                                            <Button variant="text" onClick={() => handleOpenModal(memorial)}>Learn More</Button>
                                        </Box>
                                    </CardActions>
                                </CustomCard>
                            ))
                        )}
                    </Box>
                    <Pagination
                        count={Math.ceil((filteredMemorials.length > 0 ? filteredMemorials : memorials).length / pageSize)}
                        page={currentPage}
                        onChange={handlePageChange}
                        sx={{ mt: 2 }}
                    />
                </Box>
                <ShareModal open={openShareModal} handleClose={() => setOpenShareModal(false)} shareUrl={currentShareUrl} />
            </div>
            {openModal && currentMemorial && (
                <MemorialModal open={openModal} handleClose={handleCloseModal} memorial={currentMemorial} />
            )}
        </div>
    );
}

export default Memorial_list;
