import React from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Navbar2 from './Navbar2';

const useStyles = makeStyles((theme) => ({
  contact: {
    minHeight: '100vh',
    backgroundImage: 'url(/images/contact.jpeg)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  contactOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    zIndex: 1,
  },
  contactTitle: {
    fontSize: '4rem',
    fontWeight: 'bold',
    fontFamily: "'Kaushan Script', cursive !important",
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down('md')]: {
      fontSize: '3rem',
      paddingTop: theme.spacing(6), // Increased padding for mobile devices
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.5rem',
      paddingTop: theme.spacing(8), // More padding for mobile devices
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(4), // Reduced padding for larger screens
    },
  },
  contactSubtitle: {
    fontSize: '1.2rem',
    maxWidth: '80%', // Adjusted width for larger screens
    margin: '0 auto',
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.up('lg')]: {
      maxWidth: '600px', // Reduce width on larger displays
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  formContainer: {
    background: 'rgba(255, 255, 255, 0.2)',
    backdropFilter: 'blur(10px)',
    borderRadius: '10px',
    padding: theme.spacing(4),
    width: '90%',
    maxWidth: '600px',
    marginTop: theme.spacing(4),
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      width: '100%',
    },
  },
  formField: {
    marginBottom: theme.spacing(2),
    '& label': {
      color: '#fff',
    },
    '& .MuiInputBase-root': {
      background: 'rgba(255, 255, 255, 0.3)',
      minHeight: '56px', // Ensure fields have a minimum height
      display: 'flex',
      alignItems: 'center', // Center content vertically
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5), // Adjust padding for better vertical alignment
    },
    [theme.breakpoints.down('sm')]: {
      '& .MuiInputBase-root': {
        background: 'rgba(255, 255, 255, 0.3)', // Ensure consistent background color
      },
      '& .MuiInputBase-input': {
        padding: theme.spacing(1), // Adjust padding for mobile
        minHeight: '56px', // Ensure consistent height
      },
    },
  },
  submitButton: {
    marginTop: theme.spacing(3),
    backgroundColor: '#1c3a52',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#1c3a52',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

function Contact() {
  const classes = useStyles();

  return (
    <div>
      <Navbar2 />
      <Box id="contact" className={classes.contact}>
        <div className={classes.contactOverlay} />
        <Typography variant="h1" className={classes.contactTitle}>
          Contact Us
        </Typography>
        <Typography variant="h6" className={classes.contactSubtitle}>
          If you have any questions or need further information, feel free to contact us. We're here to help and support you in any way we can.
        </Typography>
        <Box className={classes.formContainer}>
          <form noValidate autoComplete="off">
            <Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} mb={2}>
              <Box flex={1} mb={{ xs: 2, sm: 0 }} mr={{ sm: 1 }}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                />
              </Box>
              <Box flex={1} ml={{ sm: 1 }}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  className={classes.formField}
                />
              </Box>
            </Box>
            <Box mb={2}>
              <TextField
                label="Phone Number"
                variant="outlined"
                fullWidth
                className={classes.formField}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                className={classes.formField}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              className={classes.submitButton}
            >
              Send
            </Button>
          </form>
        </Box>
      </Box>
    </div>
  );
}

export default Contact;
