import React, { useEffect, useState, useRef } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { Box, Typography, Card, CardContent } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const scroll = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const StreamContainer = styled(Box)({
    display: 'flex',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '10px',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '10px',
    position: 'relative',
    width: '100%',
    height: '200px',  // Adjust height as needed
    alignItems: 'center',
});

const ScrollWrapper = styled(Box)({
    display: 'flex',
    animation: `${scroll} 15s linear infinite`,  // Reduced duration for faster scroll
});

const MessageCard = styled(Card)({
    minWidth: '250px',
    margin: '10px',
    backgroundColor: '#f8f9fa',  // Soft background color
    border: '1px solid #e0e0e0',  // Subtle border
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'scale(1.05)',
    },
});

const MessageContent = styled(CardContent)({
    padding: '15px',
    textAlign: 'left',
});

const MessageText = styled(Typography)({
    color: '#555',  // Soft text color
    marginBottom: '10px',
});

const Timestamp = styled(Typography)({
    color: '#888',  // Softer timestamp color
    fontSize: '0.8em',
});

const Message_stream = () => {
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        const fetchMessages = async () => {
            const memorialId = Cookies.get('memorialId');

            const { data: messageData, error: messageError } = await supabase
                .from('memorials')
                .select('messages')
                .eq('memorial_Id', memorialId);

            if (messageError) {
                console.error(messageError);
                return;
            }

            setMessages(messageData[0]?.messages || []);
        };

        fetchMessages();
    }, []);

    return (
        <StreamContainer>
            <ScrollWrapper>
                {messages.map((message, index) => (
                    <MessageCard key={index}>
                        <MessageContent>
                            <Typography variant="h6" style={{ fontWeight: 'bold', color: '#333' }}>
                                {message.name} {message.surname}
                            </Typography>
                            <MessageText variant="body1">
                                {message.text}
                            </MessageText>
                            <Timestamp variant="caption">
                                {new Date(message.timestamp).toLocaleString()}
                            </Timestamp>
                        </MessageContent>
                    </MessageCard>
                ))}
            </ScrollWrapper>
        </StreamContainer>
    );
};

export default Message_stream;
