import { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import {
    Button,
    Card,
    CardContent,
    Typography,
    TextField,
    Box,
    IconButton,
    Modal,
} from '@mui/material';
import Avatar from '@mui/material/Avatar';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);


function Messages() {
    const [messages, setMessages] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [newReply, setNewReply] = useState('');
    const [userName, setUserName] = useState('');
    const [userSurname, setUserSurname] = useState('');
    const [replyPath, setReplyPath] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalAction, setModalAction] = useState(''); // 'comment' or 'reply'
    const [tempMessage, setTempMessage] = useState('');
    const [tempReplyPath, setTempReplyPath] = useState(null);

    useEffect(() => {
        const fetchMessages = async () => {
            const userId = Cookies.get('userId');
            const memorialId = Cookies.get('memorialId');

            if (userId) {
                const { data: userData, error: userError } = await supabase
                    .from('Users')
                    .select('name, surname')
                    .eq('id', userId);

                if (userError) {
                    console.error(userError);
                    return;
                }

                setUserName(userData[0]?.name || '');
                setUserSurname(userData[0]?.surname || '');
            }

            const { data: messageData, error: messageError } = await supabase
                .from('memorials')
                .select('messages')
                .eq('memorial_Id', memorialId);

            if (messageError) {
                console.error(messageError);
                return;
            }

            setMessages(messageData[0]?.messages || []);
        };

        fetchMessages();
    }, []);

    const handleTextFieldClick = () => {
        const userId = Cookies.get('userId');
        if (!userId) {
            setModalAction('comment');
            setIsModalOpen(true);
        }
    };


    const handlePostMessage = async () => {
        const userId = Cookies.get('userId');
        if (!userId) {
            // Open modal to collect name, surname, and message
            setModalAction('comment');
            setIsModalOpen(true);
        } else {
            // Proceed with posting the message using stored userName and userSurname
            await postMessage(userName, userSurname, newMessage);
            setNewMessage('');
        }
    };

    const postMessage = async (name, surname, messageText) => {
        const newMessageObj = {
            name,
            surname,
            text: messageText,
            likes: 0,
            dislikes: 0,
            replies: [],
            timestamp: new Date().toISOString(),
        };

        const updatedMessages = [...messages, newMessageObj];

        const { error } = await supabase
            .from('memorials')
            .update({ messages: updatedMessages })
            .eq('memorial_Id', Cookies.get('memorialId'));

        if (error) {
            console.error(error);
            return;
        }

        setMessages(updatedMessages);
    };

    const handleLike = async (index, replyPath = []) => {
        const updatedMessages = [...messages];
        const target = getNestedMessage(updatedMessages, index, replyPath);
        target.likes += 1;
        setMessages(updatedMessages);

        const { error } = await supabase
            .from('memorials')
            .update({ messages: updatedMessages })
            .eq('memorial_Id', Cookies.get('memorialId'));

        if (error) {
            console.error(error);
        }
    };

    const handleDislike = async (index, replyPath = []) => {
        const updatedMessages = [...messages];
        const target = getNestedMessage(updatedMessages, index, replyPath);
        target.dislikes += 1;
        setMessages(updatedMessages);

        const { error } = await supabase
            .from('memorials')
            .update({ messages: updatedMessages })
            .eq('memorial_Id', Cookies.get('memorialId'));

        if (error) {
            console.error(error);
        }
    };

    const handleReply = (index, replyPath = []) => {
        const userId = Cookies.get('userId');
        if (!userId) {
            // Open modal to collect name, surname, and message
            setModalAction('reply');
            setTempReplyPath([...replyPath, index]);
            setIsModalOpen(true);
        } else {
            // Proceed with replying
            setReplyPath([...replyPath, index]);
        }
    };

    const handlePostReply = async () => {
        if (!replyPath) return;

        await postReply(userName, userSurname, newReply, replyPath);
        setNewReply('');
        setReplyPath(null);
    };

    const postReply = async (name, surname, replyText, path) => {
        const updatedMessages = [...messages];
        const target = getNestedMessage(updatedMessages, path[0], path.slice(1));
        if (!target.replies) target.replies = [];
        target.replies.push({
            name,
            surname,
            text: replyText,
            likes: 0,
            dislikes: 0,
            replies: [],
            timestamp: new Date().toISOString(),
        });

        setMessages(updatedMessages);

        const { error } = await supabase
            .from('memorials')
            .update({ messages: updatedMessages })
            .eq('memorial_Id', Cookies.get('memorialId'));

        if (error) {
            console.error(error);
        }
    };

    const getNestedMessage = (messages, index, replyPath) => {
        let target = messages[index];
        for (let i of replyPath) {
            if (!target.replies) target.replies = [];
            target = target.replies[i];
        }
        return target;
    };

    if (!messages) {
        return <p>Loading...</p>;
    }

    const renderReplies = (replies = [], replyPath) => {
        return replies.map((reply, index) => (
            <Box key={index} sx={{ marginLeft: 2 }}>
                <Box display="flex" alignItems="center">
                    <Avatar alt={reply.name} src="/images/profile.svg" />
                    <Typography variant="h6" align="left">
                        @{reply.name}_{reply.surname}
                    </Typography>
                </Box>
                <Typography variant="body1" align="left">
                    {reply.text}
                </Typography>
                <Box display="flex" alignItems="center">
                    <IconButton
                        onClick={() =>
                            handleLike(replyPath[0], [...replyPath.slice(1), index])
                        }
                    >
                        <img src="/images/red_rose_icon.png" alt="like" width="24" />
                    </IconButton>
                    <Typography>{reply.likes}</Typography>
                    <IconButton
                        onClick={() =>
                            handleDislike(replyPath[0], [...replyPath.slice(1), index])
                        }
                    >
                        <img src="/images/orange_rose_icon.png" alt="dislike" width="24" />
                    </IconButton>
                    <Typography>{reply.dislikes}</Typography>
                    <Button
                        onClick={() =>
                            handleReply(replyPath[0], [...replyPath.slice(1), index])
                        }
                    >
                        Reply
                    </Button>
                </Box>
                {renderReplies(reply.replies, [...replyPath.slice(1), index])}
            </Box>
        ));
    };

    const handleModalSubmit = async (e) => {
        e.preventDefault();
        const name = e.target.name.value;
        const surname = e.target.surname.value;
        const messageText = e.target.messageText.value;

        if (modalAction === 'comment') {
            await postMessage(name, surname, messageText);
            setNewMessage('');
        } else if (modalAction === 'reply') {
            await postReply(name, surname, messageText, tempReplyPath);
            setTempReplyPath(null);
        }

        setIsModalOpen(false);
    };

    const modalBody = (
        <Box
            component="form"
            onSubmit={handleModalSubmit}
            sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                padding: 4,
                borderRadius: 2,
            }}
        >
            <Typography variant="h6">
                {modalAction === 'comment' ? 'Add a Comment' : 'Reply to Comment'}
            </Typography>
            <TextField
                name="name"
                label="Name"
                fullWidth
                margin="normal"
                required
            />
            <TextField
                name="surname"
                label="Surname"
                fullWidth
                margin="normal"
                required
            />
            <TextField
                name="messageText"
                label="Message"
                fullWidth
                multiline
                margin="normal"
                required
            />
            <Box display="flex" justifyContent="flex-end">
                <Button type="submit">Submit</Button>
            </Box>
        </Box>
    );

    return (
        <div
            style={{
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '10px',
                padding: '10px',
            }}
        >
            <Typography variant="h6" align="left">
                {messages.length} Tributes
            </Typography>
            <TextField
                value={newMessage}
                onClick={handleTextFieldClick}  // Trigger modal on click if userId is not set
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Add a comment..."
                fullWidth
                multiline
                margin="normal"
            />
            <Box display="flex" justifyContent="flex-end">
                <Button onClick={handlePostMessage}>Post Tribute</Button>
            </Box>
            {messages.map((message, index) => (
                <Card
                    style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                    key={index}
                    sx={{ marginBottom: 2 }}
                >
                    <CardContent
                        style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)' }}
                    >
                        <Box display="flex" alignItems="center">
                            <Avatar alt={message.name} src="/images/profile.svg" />
                            <Typography variant="h6" align="left">
                                @{message.name}_{message.surname}
                            </Typography>
                        </Box>
                        <Typography variant="body1" align="left">
                            {message.text}
                        </Typography>
                        <Box display="flex" alignItems="center">
                            <IconButton onClick={() => handleLike(index)}>
                                <img src="/images/red_rose_icon.png" alt="like" width="24" />
                            </IconButton>
                            <Typography>{message.likes}</Typography>
                            <IconButton onClick={() => handleDislike(index)}>
                                <img
                                    src="/images/orange_rose_icon.png"
                                    alt="dislike"
                                    width="24"
                                />
                            </IconButton>
                            <Typography>{message.dislikes}</Typography>
                            <Button onClick={() => handleReply(index)}>Reply</Button>
                        </Box>
                        {replyPath &&
                            replyPath[0] === index &&
                            replyPath.length === 1 && (
                                <>
                                    <TextField
                                        value={newReply}
                                        onChange={(e) => setNewReply(e.target.value)}
                                        placeholder="Write a reply..."
                                        fullWidth
                                        multiline
                                        margin="normal"
                                    />
                                    <Box display="flex" justifyContent="flex-end">
                                        <Button onClick={handlePostReply}>Submit Reply</Button>
                                    </Box>
                                </>
                            )}
                        {renderReplies(message.replies, [index])}
                    </CardContent>
                </Card>
            ))}
            <Modal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                {modalBody}
            </Modal>
        </div>
    );
}

export default Messages;
