import React from 'react';
import { Grid, Box } from '@mui/material';
import Navbar from './Navbar';
import Memorial_viewer from './Memorial_viewer';
import About_card from './About_card';
import Messages from './Messages';
import Media_Player from './Media_player';
import Voice_player from './Voice_player';
import Audio_Player from './Audio_player';
import Message_stream from './Message_stream';
import ShareButton from './ShareButton';
import Rose from './Rose';
import Rose_stream from './Rose_stream';

const Memorial = () => {
    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url('/images/background3.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ padding: 3 }}>
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Grid container spacing={{ xs: 0, sm: 5 }}>

                            <Grid item xs={12} sm={9}>
                                <Memorial_viewer />
                                <Box width="100%" sx={{ mt: 2 }}>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <About_card />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Messages />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Box sx={{ mt: { sm: 8 } }}>
                                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        <br />
                                    </Box>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Media_Player />
                                    </Box>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Voice_player />
                                    </Box>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Audio_Player />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <ShareButton />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Rose />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Rose_stream />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={1} />
                        </Grid>
                    </div>
                </Box>
            </div>
        </div>
    );
}

export default Memorial;
