import React, { useState, useEffect } from 'react';
import Login from './Login';
import Registration from './Registration';
import Memorial_list from './Memorial_list';
import Cookies from 'js-cookie'; // Import js-cookie

function Authentication() {
    const [isRegister, setIsRegister] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        const id = Cookies.get('userId'); // Read the user ID from the cookie
        if (id) {
            setUserId(id);
        }
    }, []);

    const handleSetUserId = (id) => {
        setUserId(id);
        Cookies.set('userId', id); // Save the user ID as a cookie
    };

    return (
        <div>
            {userId ? (
                <Memorial_list userId={userId} />
            ) : (
                isRegister ? (
                    <Registration onSignIn={() => setIsRegister(false)} />
                ) : (
                    <Login onRegister={() => setIsRegister(true)} setUserId={handleSetUserId} />
                )
            )}
        </div>
    );
}

export default Authentication;
