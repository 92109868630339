import React, { useEffect, useState } from 'react';
import { Button, TextField, Dialog, DialogTitle } from '@mui/material';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import Navbar from './Navbar';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

function Settings() {
    const [form, setForm] = useState({
        name: '',
        surname: '',
        email: '',
        phone_number: '',
        password: ''
    });
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            const userId = Cookies.get('userId');

            const { data, error } = await supabase
                .from('Users')
                .select('name, surname, email, phone_number, password')
                .eq('id', userId)
                .single();

            if (error) {
                console.error('Error fetching user data:', error);
                return;
            }

            setForm(data);
        };

        fetchUserData();
    }, []);

    const handleChange = (event) => {
        setForm({
            ...form,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const userId = Cookies.get('userId');

        const { error } = await supabase
            .from('Users')
            .update({ ...form })
            .eq('id', userId);

        if (error) {
            console.error('Error updating user data:', error);
            return;
        }

        setOpen(true);
        setTimeout(() => setOpen(false), 2000); // Close the modal after 2 seconds
    };

    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url('/images/background5.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <form noValidate autoComplete="off" onSubmit={handleSubmit} style={{ width: '65%', backgroundColor: 'rgba(255, 255, 255, 0.95)', margin: 'auto', border: '1px solid black', borderRadius: '10px', padding: '20px' }}>
                    <h1>Account Details</h1>
                    <br />
                    <TextField fullWidth label="Name" value={form.name} onChange={handleChange} name="name" />
                    <br /> <br />
                    <TextField fullWidth label="Surname" value={form.surname} onChange={handleChange} name="surname" />
                    <br /> <br />
                    <TextField fullWidth label="Email Address" value={form.email} onChange={handleChange} name="email" />
                    <br /> <br />
                    <TextField fullWidth label="Phone Number" value={form.phone_number} onChange={handleChange} name="phone_number" />
                    <br /> <br />
                    <TextField fullWidth label="Password" value={form.password} onChange={handleChange} name="password" type="password" />
                    <br /> <br />
                    <Button variant="contained" color="primary" type="submit">Save Settings</Button>
                </form>
                <Dialog open={open} onClose={() => setOpen(false)}>
                    <DialogTitle>Account Settings Saved Successfully</DialogTitle>
                </Dialog>
            </div>
        </div>
    );
}

export default Settings;
