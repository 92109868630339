import React, { useRef, useState } from 'react';
import { TextField, Button, ImageList, ImageListItem, Box, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Asset_component = ({ selectAsset }) => {

    const assetCategories = {
        "Tombstones": ['/images/tombstone1.png', '/images/tombstone2.png', '/images/tombstone3.png', '/images/tombstone4.png'],
        "Animations": ['/images/dove.gif', '/images/orangebird.gif', '/images/dove3.gif', '/images/sunflower.gif', '/images/flower.gif', '/images/flower2.gif', '/images/rose.gif'],
        "Candles": ['/images/candle.png', '/images/candle1.png', '/images/candle2.png'],
        "Trees": ['/images/tree1.png', '/images/tree2.png', '/images/tree3.png', '/images/tree4.png', '/images/tree5.png', '/images/tree6.png'],
        "Statues": ['/images/statue1.png', '/images/statue2.png', '/images/statue3.png', '/images/statue4.png'],
        "Flowers": ['/images/flower1.png', '/images/flower2.png', '/images/flower3.png', '/images/flower4.png', '/images/flower5.png', '/images/flower6.png', '/images/flower7.png', '/images/flower8.png', '/images/flower9.png', '/images/flower10.png', '/images/flower11.png', '/images/flower12.png', '/images/flower13.png', '/images/flower14.png'],
        "Football Clubs": ['/images/football1.png', '/images/football2.png', '/images/football3.png'],
        "Car brands": ['/images/carbrand1.png', '/images/carbrand2.png', '/images/carbrand3.png'],
        "Country Flags": ['/images/flag1.png', '/images/flag2.jpg', '/images/flag3.png'],
        "Landscapes": ['/images/landscape1.png', '/images/landscape2.png', '/images/landscape3.png', '/images/landscape4.png', '/images/landscape5.png', '/images/landscape6.png', '/images/landscape7.png', '/images/landscape8.png', '/images/landscape9.png', '/images/landscape10.png', '/images/landscape11.png', '/images/landscape12.png', '/images/landscape13.png', '/images/landscape14.png', '/images/landscape15.png', '/images/landscape16.png', '/images/landscape17.png', '/images/landscape18.png', '/images/landscape19.png', '/images/landscape20.png', '/images/landscape21.png', '/images/landscape22.png', '/images/landscape23.png', '/images/landscape24.png', '/images/landscape25.png', '/images/landscape26.png', '/images/landscape27.png', '/images/landscape28.png', '/images/landscape29.png', '/images/landscape30.png', '/images/landscape31.png', '/images/landscape32.png', '/images/landscape33.png', '/images/landscape34.png', '/images/landscape35.png', '/images/landscape36.png', '/images/landscape37.png', '/images/landscape38.png', '/images/landscape39.png', '/images/landscape40.png', '/images/landscape41.png', '/images/landscape42.png', '/images/landscape43.png', '/images/landscape44.png', '/images/landscape45.png', '/images/landscape46.png', '/images/landscape47.png', '/images/landscape48.png', '/images/landscape49.png', '/images/landscape50.png', '/images/landscape51.png', '/images/landscape52.png', '/images/landscape53.png', '/images/landscape54.png'],
        "Animals": ['/images/pet1.png', '/images/cat1.png', '/images/cat2.png', '/images/chicken1.png', '/images/chicken2.png', '/images/ladybug.png', '/images/gray-crowned.png', '/images/jaguar.png', '/images/bird.png', '/images/dog2.png'],
        "Cars": ['/images/car1.png', '/images/car2.png'],
        "Other": ['/images/black_wings.png', '/images/blue_wings.png', '/images/butterflies.png', '/images/cat3.png', '/images/drawing.png', '/images/fence.png', '/images/branch.png', '/images/bicycle.png', '/images/rainbow.png', '/images/tea.png', '/images/water.png', '/images/bear.png']
    };

    const allAssets = Object.entries(assetCategories).flatMap(([category, assets]) =>
        assets.map((asset) => ({ category, src: asset }))
    );
    const [filteredAssets, setFilteredAssets] = useState(allAssets);
    const [searchTerm, setSearchTerm] = useState('');
    const scrollContainerRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const filterByCategory = (category) => {
        if (category === 'All') {
            setFilteredAssets(allAssets);
        } else {
            setFilteredAssets(allAssets.filter(asset => asset.category === category));
        }
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setFilteredAssets(allAssets.filter(asset =>
            asset.src.toLowerCase().includes(e.target.value.toLowerCase())
        ));
    };

    const handleAssetClick = (asset) => {
        selectAsset(asset.src, asset.category)();
    };

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollContainerRef.current.offsetLeft);
        setScrollLeft(scrollContainerRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const walk = (x - startX) * 2;
        scrollContainerRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    return (
        <Box sx={{ overflowX: 'auto', height: '100%', scrollbarWidth: 'none' }}>
            <TextField value={searchTerm} onChange={handleSearchChange} placeholder="Search elements..." variant="outlined" sx={{ borderColor: 'black', borderWidth: 5 }} fullWidth />
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
                <IconButton onClick={() => scrollContainerRef.current.scrollLeft -= 100} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                    <ArrowBackIcon />
                </IconButton>
                <Box
                    ref={scrollContainerRef}
                    sx={{ display: 'flex', overflowX: 'auto', gap: '10px', p: 1, cursor: 'grab', scrollbarWidth: 'none', msOverflowStyle: 'none', '&::-webkit-scrollbar': { display: 'none' } }}
                    onMouseDown={handleMouseDown}
                    onMouseLeave={handleMouseUp}
                    onMouseUp={handleMouseUp}
                    onMouseMove={handleMouseMove}
                >
                    <Button onClick={() => filterByCategory('All')} variant="contained" sx={{ minWidth: '170px', height: '30px' }} style={{ width: 'fit-content' }}>
                        Show All
                    </Button>
                    {Object.keys(assetCategories).map(category => (
                        <Button key={category} onClick={() => filterByCategory(category)} variant="contained" sx={{ minWidth: '170px', height: '30px' }} style={{ width: 'fit-content' }}>
                            {category}
                        </Button>
                    ))}
                </Box>
                <IconButton onClick={() => scrollContainerRef.current.scrollLeft += 100} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', backdropFilter: 'blur(5px)' }}>
                    <ArrowForwardIcon />
                </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
                <ImageList variant="quilted" cols={3} gap={22} >
                    {filteredAssets.map(asset => (
                        <ImageListItem key={asset.src} sx={{
                            border: '2px solid black',
                            borderRadius: '10px',
                            overflow: 'hidden',
                            '&:hover': {
                                '&:before': {
                                    content: '""',
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'rgba(0, 0, 0, 0.11)',
                                }
                            }
                        }}>
                            <LazyLoadImage
                                src={asset.src}
                                alt={asset.category}
                                onClick={() => handleAssetClick(asset)}
                                effect="blur" // add blur effect
                                style={{
                                    transition: 'transform 0.3s ease-in-out',
                                    width: '100%', // set width to 100%
                                    height: '100%', // set height to 100%
                                }}
                            //placeholder={<ThreeCircles color="#00BFFF" height={100} width={100} />} // add loading animation
                            />
                        </ImageListItem>
                    ))}
                </ImageList>

            </Box>
        </Box>
    )
}

export default Asset_component




