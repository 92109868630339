import React from 'react';
import { Grid, Box } from '@mui/material';
import Navbar from './Navbar';
import Edit_memorial_viewer from './Edit_memorial_viewer';
import About_card_editable from './About_card_editable';
import Messages from './Messages';
import Edit_media_player from './Edit_media_player';
import Edit_voice_player from './Edit_voice_player';
import Edit_audio_Player from './Edit_audio_player';
import ShareButton from './ShareButton';
import Rose from './Rose';
import Rose_stream from './Rose_stream';

const Edit_memorial2 = () => {
    return (
        <div>
            <Navbar />
            <div style={{ backgroundImage: `url('/images/background6.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ padding: 3 }}>
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Grid container spacing={{ xs: 0, sm: 5 }}>

                            <Grid item xs={12} sm={9}>
                                <Edit_memorial_viewer />
                                <Box width="100%" sx={{ mt: 2 }}>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <About_card_editable />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Messages />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Box sx={{ mt: { sm: 8 } }}>
                                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        <br />
                                    </Box>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Edit_media_player />
                                    </Box>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Edit_voice_player />
                                    </Box>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Edit_audio_Player />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <ShareButton />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Rose />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Rose_stream />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={1} />
                        </Grid>
                    </div>
                </Box>
            </div>
        </div>
    );
}

export default Edit_memorial2;
