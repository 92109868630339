import React from 'react';
import { Box, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { makeStyles } from '@mui/styles';
import Navbar2 from './Navbar2';

const useStyles = makeStyles((theme) => ({
    content: {
        height: 'auto',
        minHeight: '110vh',
        backgroundImage: 'url(/images/about_background.jpeg)',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom center',
        color: '#fff',
    },
    aboutTitle: {
        paddingTop: '10vh',
        fontFamily: "'Kaushan Script', cursive !important",
        fontSize: '3rem',
        textAlign: 'center',
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem', // Smaller font size for mobile devices
        },
    },
    aboutCarousel: {
        marginTop: theme.spacing(8),
        padding: theme.spacing(4),
    },
    carouselItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(2),
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center', // Center text on smaller screens
        },
    },
    carouselImage: {
        width: '45vw', // Default width for larger screens
        height: '50vh', // Fixed height for consistency
        objectFit: 'cover',
        borderRadius: '10px',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            width: '90vw', // Wider images on mobile devices
            height: 'auto', // Allow height to adjust for mobile
        },
    },
    carouselText: {
        marginLeft: theme.spacing(2),
        color: '#333',
        width: '100%',
        padding: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            width: '50%',
            paddingLeft: '4rem',
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 0, // Remove margin for smaller screens
        },
    },
    carouselHeader: {
        fontSize: '2.5rem',
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.8rem', // Smaller font size for mobile devices
        },
    },
    carouselBodyText: {
        fontSize: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem', // Smaller font size for mobile devices
        },
    },
}));

const items = [
    {
        image: '/images/carousel1.png',
        header: 'CREATE A FOREVER MEMORIAL FOR A DEARLY DEPARTED',
        text: 'Create a private forever memorial of a loved one using our landscapes and imagery.',
    },
    {
        image: '/images/carousel2.png',
        header: 'PERSONALISE YOUR FOREVER MEMORIAL',
        text: (
            <>
                Personalize your Forever memorial by adding photos, <br />
                music, voice clips and memorabilia onto your landscape
            </>
        ),
    },
    {
        image: '/images/carousel3.png',
        header: 'IMMORTALISE A LOVED ONE',
        text: 'Create Forever Memorials for an ancestor, a family member, friend, or pet and share with family and friends',
    },
];

function AboutUs() {
    const classes = useStyles();

    return (
        <div>
            <Navbar2 />
            <Box id="about" className={classes.content}>
                <Typography variant="h1" className={classes.aboutTitle}>
                    About Us
                </Typography>
                <Carousel
                    className={classes.aboutCarousel}
                    animation="fade" // Changed from "slide" to "fade" for the dissolve effect
                    interval={6000} // Adjust as needed for the time between slides
                    indicators={true}
                    navButtonsAlwaysVisible={true}
                    navButtonsProps={{
                        style: {
                            backgroundColor: 'white',
                            color: 'black',
                        },
                    }}
                >
                    {items.map((item, index) => (
                        <Box key={index} className={classes.carouselItem}>
                            <img
                                src={item.image}
                                alt={item.header}
                                className={classes.carouselImage}
                            />
                            <Box className={classes.carouselText}>
                                <Typography variant="h4" className={classes.carouselHeader}>
                                    {item.header}
                                </Typography>
                                <Typography variant="subtitle1" className={classes.carouselBodyText}>
                                    {item.text}
                                </Typography>
                            </Box>
                        </Box>
                    ))}
                </Carousel>
            </Box>
        </div>
    );
}

export default AboutUs;
