import React from 'react';
import { useParams } from 'react-router-dom'; // Add this line
import { Grid, Box } from '@mui/material';
import Navbar_public from './Navbar_public';
import Memorial_viewer_public from './Memorial_viewer_public';
import About_card_public from './About_card_public';
import Messages_public from './Messages_public';
import Media_Player_public from './Media_Player_public';
import Voice_player_public from './Voice_player_public';
import Audio_Player_public from './Audio_player_public';
import ShareButton_public from './ShareButton_public';
import Rose from './Rose';
import Rose_stream from './Rose_stream';
const Memorial_public = () => {
    const { memorialId } = useParams(); // Get the memorialId from the route

    return (
        <div>
            <Navbar_public />
            <div style={{ backgroundImage: `url('/images/background3.jpg')`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ padding: 3 }}>
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Grid container spacing={{ xs: 0, sm: 5 }}>

                            <Grid item xs={12} sm={9}>
                                <Memorial_viewer_public memorialId={memorialId} /> {/* Pass memorialId as a prop */}
                                <Box width="100%" sx={{ mt: 2 }}>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <About_card_public memorialId={memorialId} />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Messages_public memorialId={memorialId} />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <Box sx={{ mt: { sm: 8 } }}>
                                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
                                        <br />
                                    </Box>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Media_Player_public memorialId={memorialId} />
                                    </Box>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Voice_player_public memorialId={memorialId} />
                                    </Box>
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Audio_Player_public memorialId={memorialId} />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <ShareButton_public memorialId={memorialId} />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Rose />
                                    </Box>
                                    <br />
                                    <Box sx={{ width: { xs: '90%', sm: '100%' }, mx: 'auto' }}>
                                        <Rose_stream />
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={1} />
                        </Grid>
                    </div>
                </Box>
            </div>
        </div>
    );
}

export default Memorial_public;
