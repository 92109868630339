import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Avatar, Button, Typography, Box, Link } from '@mui/material';
import { createClient } from '@supabase/supabase-js';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const View_memorial = () => {
    const { memorialId } = useParams();
    const [memorial, setMemorial] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchMemorialData = async () => {
            const { data, error } = await supabase
                .from('memorials')
                .select('deceased_name, deceased_surname, deceased_date_of_birth, deceased_date_of_death, media')
                .eq('memorial_Id', memorialId)
                .single();

            if (error) {
                console.error('Error fetching memorial data:', error);
            } else {
                setMemorial(data);
            }
        };

        fetchMemorialData();
    }, [memorialId]);

    const findValidImage = (media) => {
        const validImageTypes = ['jpeg', 'jpg', 'png', 'webp'];
        for (let item of media) {
            if (validImageTypes.includes(item.type)) {
                return item.url;
            }
        }
        return null;
    };

    const handleViewMemorialClick = () => {
        navigate(`/Memorial_public/${memorialId}`);
    };

    if (!memorial) {
        return <Typography>Loading...</Typography>;
    }

    const { deceased_name, deceased_surname, deceased_date_of_birth, deceased_date_of_death, media } = memorial;
    const profileImage = findValidImage(media) || null;

    return (
        <Box
            sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundImage: `url('/images/background1_watermarked.png')`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                textAlign: 'center',
                color: '#fff',
                padding: 2
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1,
                }}
            />
            <Box
                sx={{
                    position: 'relative',
                    zIndex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar
                    src={profileImage}
                    alt={`${deceased_name} ${deceased_surname}`}
                    sx={{ width: 150, height: 150, marginBottom: 3 }}
                >
                    {!profileImage && <AccountCircleIcon sx={{ fontSize: 150 }} />}
                </Avatar>
                <Typography variant="h4">
                    Memorial of {deceased_name} {deceased_surname}
                </Typography>
                <Typography variant="h6">
                    {deceased_date_of_birth} - {deceased_date_of_death}
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 2 }}>
                    Published on{' '}
                    <Link href="https://forever.org.za/Home" color="inherit" underline="hover">
                        forever.org.za
                    </Link>
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 4, paddingX: 5, paddingY: 1.5 }}
                    onClick={handleViewMemorialClick}
                >
                    View Memorial
                </Button>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: '10%', // Adjust this value to raise the footer box higher on the page
                    width: '100%',
                    textAlign: 'center',
                    padding: 2,
                    zIndex: 2, // Ensure footer links are above the background overlay
                }}
            >
                <Typography variant="h5">
                    <Link
                        href="https://forever.org.za/home"
                        color="white"
                        underline="hover"
                        sx={{ marginRight: 5 }}
                    >
                        Home
                    </Link>
                    <Link
                        href="https://forever.org.za/About_us"
                        color="white"
                        underline="hover"
                        sx={{ marginRight: 5 }}
                    >
                        About Us
                    </Link>
                    <Link
                        href="https://forever.org.za/Contact"
                        color="white"
                        underline="hover"
                    >
                        Contact Us
                    </Link>
                </Typography>
            </Box>
        </Box>
    );
};

export default View_memorial;
