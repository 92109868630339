import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createClient } from '@supabase/supabase-js';
import Navbar2 from './Navbar2';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk'; // Replace with your Supabase key
const supabase = createClient(supabaseUrl, supabaseKey);
const theme = createTheme();

function Login({ onRegister, setUserId }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [invalidFields, setInvalidFields] = useState([]);

    const handleCloseModal = () => {
        setModalOpen(false);
        setModalMessage('');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const invalidFields = [];
        if (!email || !isValidEmail(email)) {
            invalidFields.push('email');
        }
        if (!password) {
            invalidFields.push('password');
        }

        if (invalidFields.length > 0) {
            setInvalidFields(invalidFields);
            setModalMessage('Please fill out all required fields correctly.');
            setModalOpen(true);
        } else {
            try {
                const { data, error } = await supabase
                    .from('Users')
                    .select('id')
                    .eq('email', email)
                    .eq('password', password)
                    .single(); // Assuming there's only one matching record

                if (error) {
                    throw error;
                }

                if (data) {
                    setUserId(data.id); // Pass the user ID to the parent component
                    setModalMessage('Login Successful!');
                    setModalOpen(true);
                } else {
                    setModalMessage('Invalid email or password.');
                    setModalOpen(true);
                }
            } catch (error) {
                console.error('Login error:', error.message);
                setModalMessage('Login error, please try again.');
                setModalOpen(true);
            }
        }
    };

    const isValidEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    return (
        <div>
            <Navbar2 />
            <ThemeProvider theme={theme}>
                <Grid
                    container
                    component="main"
                    sx={{
                        height: '100vh',
                        backgroundImage: 'url(https://images.unsplash.com/photo-1610027562252-2ed79aa944d7?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <CssBaseline />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            mb: 3,
                            mt: -6, // Move this up
                        }}
                    >
                        <img src="/images/lock.png" alt="Lock Icon" style={{ width: 40, height: 40 }} />
                        <Typography
                            component="h1"
                            variant="h5"
                            align="center"
                            sx={{
                                fontFamily: "'Kaushan Script', cursive !important",
                                color: 'white',
                                mt: 1,
                            }}
                        >
                            Sign In
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            backdropFilter: 'blur(10px)',
                            padding: 4,
                            borderRadius: 3,
                            boxShadow: 3,
                            maxWidth: 450,
                            width: '90%',
                            px: 2, // Add padding on the sides
                            '@media (max-width:600px)': { // Add specific padding for mobile devices
                                px: 3,
                            },
                        }}
                    >
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                error={invalidFields.includes('email')}
                                sx={{
                                    borderRadius: 20,
                                    '.MuiInputBase-root': {
                                        borderRadius: '20px',
                                    },
                                }}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                error={invalidFields.includes('password')}
                                sx={{
                                    borderRadius: 20,
                                    '.MuiInputBase-root': {
                                        borderRadius: '20px',
                                    },
                                }}
                            />
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    backgroundColor: '#003365',
                                    color: 'white',
                                    borderRadius: '20px',
                                    '&:hover': {
                                        backgroundColor: '#002750',
                                    },
                                }}
                            >
                                Sign In
                            </Button>
                            <Grid container>
                                <Grid item xs>
                                    <Link href="#" variant="body2" sx={{ color: 'white', fontSize: '0.9rem' }}>
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link href="#" variant="body2" sx={{ color: 'white', fontSize: '0.9rem' }} onClick={onRegister}>
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Grid>
                {modalOpen && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 9999,
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#fff',
                                padding: '20px',
                                borderRadius: '5px',
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                {modalMessage}
                            </Typography>
                            <Button variant="contained" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Box>
                    </Box>
                )}
            </ThemeProvider>
        </div>
    );
}

export default Login;
