import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import Cookies from 'js-cookie';
import { Box, Typography, Card, CardContent, Avatar } from '@mui/material';
import { styled, keyframes } from '@mui/material/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk';
const supabase = createClient(supabaseUrl, supabaseKey);

const scroll = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const StreamContainer = styled(Box)({
    display: 'flex',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    padding: '10px',
    backgroundColor: 'transparrent',
    borderRadius: '10px',
    position: 'relative',
    width: '100%',
    height: '20vh',  // Adjust height as needed
    alignItems: 'center',
});

const ScrollWrapper = styled(Box)({
    display: 'flex',
    animation: `${scroll} 15s linear infinite`,
});

const MessageCard = styled(Card)({
    minWidth: '250px',
    margin: '10px',
    height: '8vh',
    backgroundColor: '#f8f9fa',
    border: '5px solid #e0e0e0',
    borderRadius: '15px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s',
    '&:hover': {
        transform: 'scale(1.05)',
    },
});

const MessageContent = styled(CardContent)({
    padding: '2px',
    textAlign: 'left',
    display: 'flex',
    alignItems: 'center',
});

const MessageText = styled(Typography)({
    color: '#555',
    marginLeft: '10px',
});

const Rose_stream = () => {
    const [roses, setRoses] = useState([]);

    useEffect(() => {
        const fetchRoses = async () => {
            const memorialId = Cookies.get('memorialId');

            const { data, error } = await supabase
                .from('memorials')
                .select('roses')
                .eq('memorial_Id', memorialId)
                .single();

            if (error) {
                console.error(error);
                return;
            }

            if (data?.roses && data.roses.length > 0) {
                setRoses(data.roses);
            }
        };

        fetchRoses();
    }, []);

    if (roses.length === 0) {
        return null; // Do not render the component if there are no roses
    }

    return (
        <StreamContainer>
            <ScrollWrapper>
                {roses.map((rose, index) => (
                    <MessageCard key={index}>
                        <MessageContent>
                            <img src="/images/red_rose_trans.png" alt="Rose Icon" style={{ width: 50, height: 50, marginRight: '10px' }} />
                            {rose.profile_picture ? (
                                <Avatar src={rose.profile_picture} alt={`${rose.name} ${rose.surname}`} />
                            ) : (
                                <AccountCircleIcon fontSize="large" />
                            )}
                            <MessageText variant="p">
                                {rose.name} {rose.surname}
                            </MessageText>

                        </MessageContent>
                    </MessageCard>
                ))}
            </ScrollWrapper>
        </StreamContainer>
    );
};

export default Rose_stream;
