import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import Navbar2 from './Navbar2';

const supabaseUrl = 'https://ahimpdkjedsuidziiths.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImFoaW1wZGtqZWRzdWlkemlpdGhzIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTI4NTA1NDIsImV4cCI6MjAyODQyNjU0Mn0.9rPs2gIzMYYqHXHQHU298xc58r6lpcCE_a7ooG5vRQk'; // Replace with your Supabase key
const supabase = createClient(supabaseUrl, supabaseKey);

function Registration({ onSignIn }) {
    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [invalidFields, setInvalidFields] = useState([]);

    const handleCloseModal = () => {
        setModalOpen(false);
        setModalMessage('');
    };

    const handleSubmit = async () => {
        const invalidFields = [];
        if (!firstName) {
            invalidFields.push('firstName');
        }
        if (!surname) {
            invalidFields.push('surname');
        }
        if (!email || !isValidEmail(email)) {
            invalidFields.push('email');
        }
        if (!password) {
            invalidFields.push('password');
        }
        if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
            invalidFields.push('phoneNumber');
        }

        if (invalidFields.length > 0) {
            setInvalidFields(invalidFields);
            setModalMessage('Please fill out all required fields correctly.');
            setModalOpen(true);
        } else {
            try {
                const { error } = await supabase
                    .from('Users')
                    .insert([
                        {
                            name: firstName,
                            surname: surname,
                            email: email,
                            password: password,
                            phone_number: phoneNumber,
                            verified: false
                        },
                    ]);
                if (error) {
                    throw error;
                }
                setModalMessage('Signed Up Successfully!');
                setModalOpen(true);
                setFirstName('');
                setSurname('');
                setEmail('');
                setPassword('');
                setPhoneNumber('');
            } catch (error) {
                console.error('Error inserting data:', error.message);
                setModalMessage('Something went wrong, please try again...');
                setModalOpen(true);
            }
        }

        setTimeout(() => {
            handleCloseModal();
        }, 3000);
    };

    const isValidEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const isValidPhoneNumber = (phoneNumber) => {
        const parsedNumber = parsePhoneNumberFromString(phoneNumber);
        return parsedNumber ? parsedNumber.isValid() : false;
    };

    return (
        <div>
            <Navbar2 />
            <ThemeProvider theme={createTheme()}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundImage: 'url(/images/background7.jpg)', // Replace with your actual background image path
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundAttachment: 'fixed', // Optional: keeps the background fixed during scroll
                        zIndex: -1, // Place background behind other elements
                    }}
                />
                <Container
                    component="main"
                    maxWidth="xs"
                    sx={{
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        position: 'relative', // Allows proper positioning of the icon and text above
                    }}
                >
                    <CssBaseline />
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '20%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            transform: 'translateY(-50%)',
                        }}
                    >
                        <img
                            src="/images/lock.png"
                            alt="Lock Icon"
                            style={{ width: '50px', height: '50px' }}
                        />
                        <Typography
                            component="h1"
                            variant="h5"
                            sx={{ fontFamily: 'Kaushan Script', color: 'white' }}
                        >
                            Sign Up
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                            backdropFilter: 'blur(10px)',
                            padding: 4,
                            borderRadius: 2,
                            width: '100%', // Ensure the form is full width within container
                            maxWidth: 400, // Fixed max width for the form
                            opacity: '90%',
                            mt: 10, // Adjust margin to prevent overlap with the sign-up text and icon
                        }}
                    >
                        <Box component="form" noValidate sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="firstName"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        autoFocus
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        error={invalidFields.includes('firstName')}
                                        sx={{
                                            borderRadius: 20,
                                            '.MuiInputBase-root': {
                                                borderRadius: '20px',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="surname"
                                        label="Surname"
                                        name="surname"
                                        autoComplete="family-name"
                                        value={surname}
                                        onChange={(e) => setSurname(e.target.value)}
                                        error={invalidFields.includes('surname')}
                                        sx={{
                                            borderRadius: 20,
                                            '.MuiInputBase-root': {
                                                borderRadius: '20px',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        autoComplete="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        error={invalidFields.includes('email')}
                                        sx={{
                                            borderRadius: 20,
                                            '.MuiInputBase-root': {
                                                borderRadius: '20px',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="new-password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        error={invalidFields.includes('password')}
                                        sx={{
                                            borderRadius: 20,
                                            '.MuiInputBase-root': {
                                                borderRadius: '20px',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="phoneNumber"
                                        label="Phone Number"
                                        type="tel"
                                        id="phoneNumber"
                                        autoComplete="tel"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        error={invalidFields.includes('phoneNumber')}
                                        sx={{
                                            borderRadius: 20,
                                            '.MuiInputBase-root': {
                                                borderRadius: '20px',
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        control={<Checkbox value="allowExtraEmails" color="primary" />}
                                        label="I want to receive inspiration, marketing promotions and updates via email."
                                    />
                                </Grid>
                            </Grid>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3,
                                    mb: 2,
                                    backgroundColor: '#B79593',
                                    color: 'white',
                                    borderRadius: '20px',
                                    '&:hover': {
                                        backgroundColor: '#002750',
                                    },
                                }}
                                onClick={handleSubmit}
                            >
                                Sign Up
                            </Button>

                            <Grid container justifyContent="flex-end">
                                <Grid item>
                                    <Link href="#" variant="body2" sx={{ color: 'white', fontSize: '0.9rem' }} onClick={onSignIn}>
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Container>
                {modalOpen && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 9999,
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: '#fff',
                                padding: '20px',
                                borderRadius: '5px',
                                textAlign: 'center',
                            }}
                        >
                            <Typography variant="h6" gutterBottom>
                                {modalMessage}
                            </Typography>
                            <Button variant="contained" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </Box>
                    </Box>
                )}
            </ThemeProvider>
        </div>
    );
}

export default Registration;
